'use strict'

var MethodsService = function () {
    var serviceUrl = '/umbraco/api/content/';

    this.fetchMethodsByIds = function (ids, liClasses, culture, callback) {
        var data = {
            ids: ids,
            liClasses: liClasses,
            culture: culture
        };
        xhrRequest('Get', 'GetByIds', data, function (response) {
            callback(response);
        });
    }


    this.fetchMethodsByFilter = function (filter, liClasses, culture, callback) {
        var data = {
            age: filter.age,
            category: filter.categories,
            page: filter.page,
            liClasses: liClasses,
            culture: culture
        };
        xhrRequest('Get', 'GetByFilter', data, function (response) {
            callback(response);
        });
    }


    function xhrRequest(type, action, data, onSuccess) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                var jsonResponse = JSON.parse(xhr.response);
                if (onSuccess) {
                    onSuccess(jsonResponse);
                }
            }
        };

        var params = '';
        if (data) {
            params = '?';

            for (var propt in data) {

                if (data[propt]) {
                    params += propt + '=' + data[propt] + '&';
                }
            }
        }

        params = params.slice(0, params.length - 1);

        xhr.open(type, serviceUrl + action + params);
        xhr.send();
    }
};

window.methodsService = new MethodsService();