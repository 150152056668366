/**
 * Adds an event
 *
 * @param {String} event
 * @param {Function} callback
 */
function addEvent(event, callback) {
    events[event].push(callback);
}

/**
 * Event: Page ready
 */
function onPageReady() {
    for (var i = 0; i < events.pageready.length; i++) {
        events.pageready[i]();
    }
}

// --------------------
// Init
// --------------------
// Store all event callbacks
var events = {
    pageready: [],
};

// Hook up page ready event
document.addEventListener("DOMContentLoaded", onPageReady);