'use strict'

var FavouritesService = function () {
    var favourites = [];
    var processQueue = [];
    var onChangeEventHandlers = [];
    var favouritesLoaded = false;
    var sessionKey = null;
    var self = this;

    var initialize = function () {
        sessionKey = 'favourite-methods';

        // try to load from session storage
        var loadedFavourites = loadFavouritesFromLocalStorage();

        if (!loadedFavourites) {
            loadedFavourites = [];
        }

        onFavouritesLoaded(loadedFavourites);
    };
    initialize();

    this.isFavourite = function (id, callback) {
        if (!favouritesLoaded) {
            processQueue.push(function () {
                self.isFavorite(id, callback);
            });
        } else {
            var isFav = findInFavourites(id) != null;
            callback(isFav);
        }
    };

    this.addToFavourites = function (id, callback) {
        if (!favouritesLoaded) {
            processQueue.push(function () {
                self.addToFavourites(id, callback);

            });
        } else {
            // if the id already matches a favorite, do nothing
            if (findInFavourites(id) != null) {
                return;
            }

            // add to favourites storage object
            favourites.push(id);

            // update local storage
            saveFavouritesInLocalStorage();

            processOnChangeHandlers();

            if (callback) {
                callback(true);
            }            
        }
    };

    this.removeFromFavourites = function (id, callback) {
        if (!favouritesLoaded) {
            processQueue.push(function () {
                self.removeFromFavourites(id, callback);
            });
        } else {
            // if the id does not match a favorite, do nothing
            if (findInFavourites(id) == null) {
                return;
            }

            // remove from favourites storage object
            var favIndex = findInFavourites(id);
            favourites.splice(favIndex, 1);

            // update local storage
            saveFavouritesInLocalStorage();

            processOnChangeHandlers();

            if (callback) {
                callback(true);
            }
        }
    };

    this.getAllFavourites = function (callback) {
        if (!favouritesLoaded) {
            processQueue.push(function () {
                self.getAllFavourites(callback);
            });
        } else {
            // copy the array, so the client can not change the array
            var favouritesCopy = [];
            for (var i = 0; i < favourites.length; i++) {
                favouritesCopy[i] = favourites[i];
            }

            callback(favouritesCopy);
        }
    }

    this.clearAllFavourites = function (callback) {
        if (!favouritesLoaded) {
            processQueue.push(function () {
                self.clearAllFavourites(callback);
            });
        } else {
            favourites = [];
            saveFavouritesInLocalStorage();
            processOnChangeHandlers();

            if (callback) {
                callback(true);
            }
        }
    }

    this.getTotalFavourites = function (callback) {
        if (!favouritesLoaded) {
            processQueue.push(function () {
                self.getTotalFavourites(callback);
            });
        } else {
            callback(favourites.length);
        }
    };

    this.addOnChangeHandler = function (callback) {
        if (callback && findOnChangeHandler(callback) < 0) {
            onChangeEventHandlers.push(callback);
        }
    }

    this.removeOnChangeHandler = function (callback) {
        var callbackIndex = findOnChangeHandler(callback);
        if (callback && callbackIndex >= 0) {
            onChangeEventHandlers.splice(callbackIndex, 1);
        }
    }

    function findOnChangeHandler (callback) {
        for (var i = 0; i < onChangeEventHandlers.length; i++) {
            if (onChangeEventHandlers[i] == callback) {
                return i;
            }
        }
        return -1;
    }

    function processOnChangeHandlers () {
        // invoke all pending processes
        for (var i = 0; i < onChangeEventHandlers.length; i++) {
            onChangeEventHandlers[i]();
        }
    }

    function findInFavourites(id) {
        for (var i = 0; i < favourites.length; i++) {
            var fav = favourites[i];

            if (fav == id) {
                return i;
            }
        }

        return null;
    }

    function loadFavouritesFromLocalStorage() {
        // load from sessionKey
        var favs = JSON.parse(localStorage.getItem(sessionKey));
        return favs;
    }

    function saveFavouritesInLocalStorage() {
        // save to sessionKey
        localStorage.setItem(sessionKey, JSON.stringify(favourites));
    }

    function onFavouritesLoaded(favs) {
        favourites = favs;
        favouritesLoaded = true;

        // invoke all pending processes
        for (var i = 0; i < processQueue.length; i++) {
            processQueue[i]();
        }
    }
};

window.favouritesService = new FavouritesService();