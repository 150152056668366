var tabs = document.getElementsByClassName('tab-title');

function displayContent(id) {
    var tabs = document.getElementsByClassName('tab-title');
    var content = document.getElementsByClassName('tab-content-text');

    var thisContent = document.querySelector("." + id);
    var thisTab = document.querySelector("#" + id);


    for (let item of content) {
        item.classList.remove('active-content');
    }

    for (let tab of tabs) {
        tab.classList.remove('active-tab');
    }

    thisTab.classList.add('active-tab');
    thisContent.classList.add('active-content');

    
};

for (let tab of tabs) {
    tab.addEventListener("click", ()=>{displayContent(tab.id)});
}
